/*-------------------------------------------------------------------------------------------------
Author: Justin Herrera <me@justin-herrera.com>
Version 1.0
-------------------------------------------------------------------------------------------------*/
;(function($){

	window.Astral = {
		// Settings object
		settings: {			
			isAdmin: false,
			jQuery: $ // store unaltered jQuery reference for use in modules if needed
		},

		// Vars (easily accessible throughout modules)
		vars: {
			animationEvents: {
				end: 'animationend webkitAnimationEnd oanimationend MSAnimationEnd',
				iteration: 'animationiteration webkitAnimationIteration oanimationiteration MSAnimationIteration',
				start: 'animationstart webkitAnimationStart oanimationstart MSAnimationStart',
				transitionend: 'transitionend webkitTransitionEnd otransitionend MSTransitionEnd'
 			},
 			breakpoints:{
 				large: 1200,
 				desktop: 992,
				tablet: 768,
				phablet: 480,
				phone: 320
 			},
 			edgeSpinner: '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-notch" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle-notch fa-w-16 fa-spin fa-lg"><path d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>'
		},

		// 'Constructor'
		init: function(){
			// Check if Admin
			_Astral.settings.isAdmin = _Core.checkAdmin();

			// Initiate various events after DOM loads
			document.addEventListener('DOMContentLoaded', function(){
				// Initiate pre-DOM ready events
				_Core.publish('pre');

				// Initiate DOM ready events
				_Core.publish('dom');

				// Initiate post-DOM ready events
				_Core.publish('post');
			});

			// Initiate any functionality needed after resources are loaded
			window.onload = function(){ _Core.publish('load'); };

			// Initiate Misc functoins
			_Astral.initiateMisc();
		},

		/**
		 * Initiate Misc Functions
		 * This is a catch-all for extremely small functions that don't worrent a full module
		 */
		initiateMisc: function(){
			// Misc Small Custom IE Check for this project
			if(astralDetect.browser('edge')){
				$('html').addClass('isEdge');

				// Edge doesn't recognize list-style:none; when applied to an element with display:none; at page-load.
				// This hack forces Edge to reapply the styles. More info:
				// https://github.com/webhintio/webhint.io/issues/210 (CSS fixes didn't seem to work and/or would require more hackery)
				$('ul:hidden').each(function(){
					$(this).parent().append($(this).detach());
				});

				// Replace cool SVG spinners with lame spinner since Edge can't animate SVG properly
				$('span.spinner').empty().html(Astral.vars.edgeSpinner);
			}

			// Handle visibility of back-to-top button
			$(window).on('scroll', function(e){
				var oBtn = $('#top');

				// Only remove class if user didn't trigger the click (preventing 'flashing' on/off)
				if(window.pageYOffset > 70 && !oBtn.hasClass('isTriggered')){
					oBtn.removeClass('hidden');
				}else{
					oBtn.addClass('hidden');
				}
			});

			// Hide Back-to-Top Button when it's clicked
			$('#top').on('click', function(e){
				var oBtn = $(this);

				// Hide button and add class indicating user triggered
				oBtn.addClass('hidden isTriggered');

				$('html,body').animate({
					scrollTop: 0
				}, 250, function(e){
					oBtn.removeClass('isTriggered');
				});

				return false;
			});
		},

		// These are core functions which are required for our psuedo-module loader to work
		core: {
			// Utilize functions fired by various Admin/CMS Tools in case Modules need to do things differently in 'edit' mode
			checkAdmin: function(){
				// Sitecore Check
				if(typeof Sitecore !== 'undefined' && Sitecore.PageModes.PageEditor){return true;}

				// EPI Server Check
				if(typeof PageEditing !== 'undefined' && PageEditing.PageIsInEditMode){return true;}

				// Local Check/Override
				if(window.location.href.indexOf('local') > -1){return true;}
				
				// Default
				return false;
			},

			/**
			 * Check if Dependencies are loaded
			 * This merely checks to see if the dependencies are loaded first whether utility or module. It could in fact exist in eitehr array and has not been loaded first. There is no function that re-arranges or tries to predict when/where dependencies need to be loaded in a correct order. This should be done by a script level within the HTML.
			 * 
			 * @param  {str} name [module/utility name]
			 * @param  {array} dependencies [list of dependency names for this module/utility]
			 * @output {error} [outputs console errors if provided dependencies are not loaded]
			 */
			checkDependencies: function(name, dependencies){
				dependencies.forEach(function(dependency){
					try{
						if(_Astral.loaded.indexOf(dependency) == -1){
							throw new Error('"' + name + '" is expecting dependency: "' + dependency + '." Make sure "'+ dependency +'" is included and loaded before "'+ name +'."');
						}
					}catch(err){
						console.error(err.message);
					}
				});
			},

			/**
			 * Load Module !!!NEEDS TESTING!!!
			 * Run this function is used when a module is loaded after page initiatlization; usually via AJAX
			 * 
			 * @param  {obj} oModule [creator object for an imported feature]
			 * @param  {array} oFunctions [an array of functions to be run immediately]
			 */
			load: function(oModule, oFunctions){
				// Register as normal
				_Core.register(oModule);

				// Loop thru functions array and run said functions now that the module is loaded
				oFunctions.forEach(function(sFunction){
					_Astral[oModule.functionName].sFunction();
				});
			},

			/**
			 * Register
			 * This runs through 
			 *
			 * @param {obj} oModule [creator object for an imported feature]
			 */
			register: function(oModule){
				// Verify this wasn't included multiple times before proceeding
				if(_Astral.loaded.indexOf(oModule.functionName) == -1){
					// Check if dependencies are loaded
					_Core.checkDependencies(oModule.name, oModule.dependencies);

					// Subscribe
					_Core.subscribe(oModule.functionName, oModule.subscribers);

					// Push to loaded to prevent being loaded again
					_Astral.loaded.push(oModule.functionName);
				}
			},

			/**
			 * Subscribe
			 * This function applies any subscribers to events given to it whether thru module loading or setting elsewhere 
			 * 
			 * @param  {string} functionName [the module's calling function name]
			 * @param  {obj} subscribers [each key is an event with an array of functions, as strings, tied to that event]
			 */
			subscribe: function(functionName, subscribers){
				// Cycle thru all provided subscribers
				Object.keys(subscribers).forEach(function(subscriber){
					var handlers = subscribers[subscriber];

					// Skip unless handlers are tied to a given event
					if(handlers.length > 0){
						// Verify subscriber doesn't exist, if so create entry
						if(_Subscribers[subscriber] === undefined){
							_Subscribers[subscriber] = [];
						}

						// Push handlers to subscribers array
						handlers.forEach(function(handler){
							_Subscribers[subscriber].push(_Astral[functionName][handler]);
						});
					}
				});
			},

			/**
			 * Publish
			 * This function fires all handlers tied to a subscriber
			 *
			 * @param {str} subscriber [this is the subscriber event's name]
			 * @param {obj} data [an object containing any type of data that may be needed for the handling function]
			 */
			publish: function(subscriber, data){
				var _subscribers = _Astral.subscribers;

				// Verify that there are indeed items in the object
				if(!_subscribers[subscriber]){ return; }

				// Cycle thru all handlers tied to subscriber and call them
				_subscribers[subscriber].forEach(function(handler){
					// Call handler with data if it's in fact declared
					if(handler){ handler(data); }
				});
			}
		},
		modules: [], // holds modules injected into Astral
		utilities: [], // holds utilities injected into Astral
		subscribers: [], // holds custom events to subscribe to
		loaded: [] // holds loaded modules
	}

	// 'Private' Variables
	var _Astral = Astral,
		_Core = _Astral.core,
		_Modules = _Astral.modules,
		_Utilities = _Astral.utilities,
		_Subscribers = _Astral.subscribers;

})(jQuery); // Pass jQuery global var to scope as $ within

// Initiate Astral
Astral.init();
